.content {
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-heading {
  text-transform: uppercase;
  font-family: 'headings', sans-serif;
  font-size: calc(24px * var(--textScale));
  font-weight: 700;
}

.content-meta {
  font-size: calc(13px * var(--textScale));
  margin-top: 4px;

  a {
    color: var(--cardLinkColor);

    &:hover {
      text-decoration: underline;
    }
  }
}

.content-description {
  font-size: calc(13px * var(--textScale));
  line-height: 1.65;
  margin-top: 12px;

  & > * + * {
    margin-top: 20px;
  }

  li + li {
    margin-top: 4px;
  }

  ul li {
    list-style: disc;
    margin-left: 18px;
  }

  ol li {
    list-style: decimal;
    margin-left: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: calc(13px * var(--textScale));
    font-weight: bold;
  }

  & > h1 + *,
  & > h2 + *,
  & > h3 + *,
  & > h4 + *,
  & > h5 + *,
  & > h6 + * {
    margin-top: 4px;
  }

  a {
    color: var(--cardLinkColor);

    &:hover {
      text-decoration: underline;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid $border;
    text-align: left;
    padding: 7px;
    font-size: 13px;
  }
}

.content-special-button {
  background: var(--scanBackgroundColor);
  color: var(--scanTextColor);
  width: 100%;
  border-radius: calc(var(--borderRadius) * 2);
  line-height: 50px;
  margin-top: 18px;
  padding-top: 2px;
  font-weight: 600;
  position: relative;
  transition: 0.4s opacity;

  &--no-wallet {
    pointer-events: none;
    opacity: 0.5;
  }

  &__spinner {
    pointer-events: none;
    opacity: 0;
    transition: 0.4s opacity;
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -10px;
    animation: 1s infinite rotate linear;
  }

  &--pending {
    opacity: 0.7;
    pointer-events: none;

    svg {
      opacity: 1;
    }
  }

  &--trans {
    opacity: 0.7;
  }

  &__claimed {
    opacity: 0;
    transition: 0.2s opacity;
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -10px;
    background: var(--scanTextColor);
    color: var(--scanBackgroundColor);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--claimed {
    pointer-events: none;
  }

  &--claimed .content-special-button__claimed {
    opacity: 1;
  }
}
