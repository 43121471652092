.header {
  display: flex;
  align-items: center;
  padding: 24px var(--headerPadding);
  justify-content: space-between;

  img {
    width: var(--logoSize);
  }
}
