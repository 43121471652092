.content-detail {
  h2 {
    margin-top: 16px;
    margin-bottom: 4px;
    font-weight: 400;
    color: var(--cardSubheadingColor);
    font-size: calc(12px * var(--textScale));
  }

  p {
    word-break: break-all;
    font-size: calc(13px * var(--textScale));
    font-weight: bold;
  }
}
